.pr-table {
    display: none;
}

.pr-table-container {
    display: none;
}

.pr-logo {
    display: none;
}

@media print {

@page {
    size: landscape; /* Ustalenie rozmiaru strony (np. A4) */
    margin: 1cm 0.5cm 1cm 0.5cm; /* Ustawienie marginesów */
}
    .pr-logo {
        display: block;
    }

/*    .pr-table-container {
        display: block;
        width: 100%;
        height: 100%;
        padding: 25px 15px 30px 15px;
    }*/

    .pr-table {
        display: flex;
        width: 100%;
        font-size: 10px;
        size: portrait;
    }

    table.pr-table { width: 100%; }

    thead.pr-table { width: 100%; }

    .pr-table th, .pr-table td {
        text-align: left;
        padding: 4px;

        flex: 1 1 0px;
    }

    .pr-table td {
        border: 1px solid black;
    }

    .td-head {
        background-color: #91ace1;
        font-weight: bold;
        font-size: 11px;
        letter-spacing: 2px;
    }

    .pr-table tr {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }



}
