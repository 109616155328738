.App {
  text-align: center;
/*  background: linear-gradient(90deg, rgba(231, 102, 107, 0.06) 0%, rgba(245, 141, 145, 0.6) 50%) !important;*/
  background: linear-gradient(90deg, rgb(246, 214, 216) 10%, rgb(253, 238, 238) 80%) !important;
}

.printMe { display: none;}
.printMeHide { display: none;}
@media print {
  .no-printMe  { display: none;}
  .printMeHide  { display: block;}
  .printMeShow { display: block;}
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
/*  background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu-item:hover, .ant-menu-submenu-item:hover, .ant-menu-submenu-title:hover {
  background-color: rgba(255, 0, 6, 0.2) !important;
}

.ant-menu-item-selected, .ant-menu-submenu-selected .ant-menu-item-selected:hover {
  background-color: #ef343a !important;
  color: white !important ;
}

.ant-menu {
  border: none !important;
  transition: none !important;
}

.layout-transparent {
  background: transparent !important;
}

.site-layout {
  background: transparent !important;
}

.ant-spin-dot-item {
  background-color: #ef343a !important;
}

h1 {
  font-size: 2em;
  letter-spacing: 3px;
}

.ant-table-footer {
  height: 25px !important;
}

