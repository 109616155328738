.logo {
    height: 40px;
    margin: 16px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 100px;
    padding: 6px;
}

.logo img {
    width: 190px;
}

.sider {
    border-width: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
